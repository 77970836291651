<!-- 获取微信定位 -->
<template>
  <div class="con">
    <h1 @click="getWeixinPoi">获取定位</h1>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    getWeixinPoi () {
      this.$ownerUtil.getWxLocaltion('getWeixinPoi')
    }
  },
  mounted () {
    var objectData = {
      appId: this.$store.getters.getInitData.appId,
      appSecret: this.$store.getters.getInitData.appSecret
    }
    this.oppenId = this.$ownerUtil.getUserCodeConfig('getWeixinPoi', objectData)
    this.oppenId = this.oppenId.data.openId
  }
}
</script>
<style lang="scss" scoped>
.con {
  width: 100%;
  height: 100%;
}
</style>
