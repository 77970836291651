<!-- 便民知识 -->
<template>
  <div class="con">
    <div class="searchDiv">
      <div class="inputDiv">
        <mu-text-field class="inputItem" v-model="searchword" @change="search" label="请输入关键字进行查询" action-icon="search" suffix="" label-float ></mu-text-field>
      </div>
    </div>
    <mu-container ref="container" class="loadmore-content">
      <mu-load-more :loading="loading" @load="load" :loaded-all="loadingAll">
        <el-card class="card animated fadeInRight" @click.native="cardClick(e.id)" :style="`animation-delay:${Math.random() * 1}s`"  :body-style="{ padding: '0px' }" v-for="(e, i) in returnDataArr" :key="i">
          <div class="clearfix header">
            <span class="wt">{{e.wt}}</span>
          </div>
          <div class="mx" style="padding: 14px;">
            <span v-html="e.mx"></span>
          </div>
        </el-card>
      </mu-load-more>
      <div class="loadingWord" v-if="loadingAll">
        <mu-icon value="done" color="#A9DD88"></mu-icon>
        加载已完成
      </div>
    </mu-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchword: '',
      num: 0,
      returnData: '',
      returnDataArr: [],
      // refreshing: false,
      loading: false, // 是否正在加载数据
      text: 'List',
      currPage: 0, // 当前页
      totalPage: 2, // 总页数
      loadingAll: false // 是否已经完成加载
    }
  },
  components: {},
  computed: {},
  methods: {
    load () {
      this.loading = true
      var vm = this
      var postData = {
        searchword: vm.searchword,
        currPage: vm.currPage,
        pageSize: 20
      }
      vm.$axios.post('/peopleKnowledge', postData).then(successData => {
        vm.returnData = successData.data.data[0]
        vm.totalPage = vm.returnData.totalPage

        vm.returnData.data.forEach(e => {
          // e.mx = decodeURI(window.atob(e.mx)) // mx 后台传过来是base64
          vm.returnDataArr.push(e)
        })

        vm.currPage += 1
        if (vm.currPage > vm.totalPage || vm.currPage === vm.totalPage) {
          vm.loadingAll = true
        } else {
          this.loading = false
        }
      })
      // setTimeout(() => {
      //   this.loading = false
      //   this.num += 10
      //   this.currPage += 1
      //   this.currPage > this.totalPage && (this.loadingAll = true)
      // }, 800)
    },
    search () {
      this.currPage = 0
      this.returnDataArr = []
      this.load()
    },
    cardClick (id) {
      setTimeout(() => {
        this.$parent.openFullscreenDialog('便民知识', `index.html#/infoDetails?id=${id}&type=bmzs`)
      }, 400)
    }
  },
  mounted () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/bmzs.scss';
</style>
