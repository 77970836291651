<!-- 我的诉求列表 -->
<template>
  <div class="con">
    <div class="searchDiv">
      <div class="inputDiv" @click="showConsole">
        <mu-text-field class="inputItem" v-model="searchWord" @change="search" label="请输入诉求标题进行查询" action-icon="search" suffix="" label-float ></mu-text-field>
      </div>
    </div>
    <div class="btnZu" style="display: none">
      <mu-tabs :value.sync="acitive" indicator-color="#3282E7" inverse full-width >
        <mu-tab v-for="(e, i) in tabBtn" :key="i" @click="tabClick(e)">
          <mu-badge content="12" circle color="secondary"  class="demo-icon-badge">
          {{e.name}}
          </mu-badge>
        </mu-tab>
        <!-- <mu-tab>处理中</mu-tab>
        <mu-tab>待回访</mu-tab>
        <mu-tab>已办结</mu-tab> -->
      </mu-tabs>
    </div>
    <mu-container ref="container" class="loadmore-content">
      <mu-load-more :loading="loading" @load="load" :loaded-all="loadingAll">
        <el-card class="card animated fadeInRight" :style="`animation-delay:${Math.random() * 1}s`"  :body-style="{ padding: '0px' }" v-for="(e, i) in returnDataArr" @click.native="cardClick(e.id)" :key="i">
          <div class="textDiv">
            办件项目: {{e.bjxm}} <br/>
            申办时间: {{e.sbsj}} <br/>
            <!-- <span v-if="e.cnsx">
              承诺时限: {{e.cnsx}}
            </span> -->
            <span v-if="e.sjbjsj">实际办件时间: {{e.sjbjsj}}</span>
          </div>
          <div class="stateDiv" :class="e.state">
            {{e.stateName}}
          </div>
        </el-card>
        <!-- <el-card class="card animated fadeInRight" :style="`animation-delay:${Math.random() * 1}s`"  :body-style="{ padding: '0px' }" v-for="(e, i) in num" @click.native="cardClick(i)" :key="i">
          <div class="textDiv">
            办件项目: 华侨收养登记 <br/>
            申办时间: 2018-11-12 00:00 <br/>
            承诺时限: 15个工作日 <br/>
            <span>实际办件时间: 2008-11-01 00:00</span>
          </div>
          <div class="stateDiv" :class="state">未办理</div>
        </el-card> -->
      </mu-load-more>
      <div class="loadingWord" v-if="loadingAll">
        <mu-icon value="done" color="#A9DD88"></mu-icon>
        {{returnDataArr.length === 0 ? '暂无数据' : '加载已完成'}}
      </div>
    </mu-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      clickCount: 0,
      state: 'wbj',
      oppenId: '',
      searchWord: '',
      leibiehasId: [],
      returnData: '',
      returnDataArr: [],
      num: 0,
      // refreshing: false,
      loading: false,
      acitive: 0,
      stateName: '待处理',
      tabBtn: [
        {
          name: '待处理',
          state: 'dcl'
        }, {
          name: '处理中',
          state: 'clz'
        }, {
          name: '待回访',
          state: 'dhf'
        }, {
          name: '已办结',
          state: 'ybj'
        }
      ],
      text: 'List',
      currPage: 0, // 当前页
      totalPage: 2, // 总页数
      postState: 'all',
      loadingAll: false // 是否已经完成加载
    }
  },
  components: {},
  computed: {},
  created () {
    this.initUserOpenId()
  },
  methods: {
    showConsole () {
      var vm = this
      vm.clickCount++
      if (vm.clickCount === 10) {
        var Vconsole = this.$Vconsole
        Vconsole = new Vconsole()
        console.log(Vconsole)
      }
    },
    initUserOpenId () { // 得到用户的openId
      var vm = this
      // var userConfig = this.$ownerUtil.getAppUserCodeConfig()
      // this.oppenId = userConfig.openid
      // this.oppenId = 'ovNPw5tfw6gHjZpOzVniT7KCx9lc'

      var objectData = {
        appId: this.$store.getters.getInitData.appId,
        appSecret: this.$store.getters.getInitData.appSecret
      }
      this.oppenId = this.$ownerUtil.getUserCodeConfig('sqcx', objectData)
      this.oppenId = this.oppenId.data.openId

      var postData = {
        oppenId: this.oppenId
      }
      vm.$axios.post('/realNameCheck', postData).then(res => {
        if (!res.data.data.isSuccess) {
          vm.$router.replace({
            path: '/xxgrxx'
          })
        }
      })
    },
    tabClick (e) {
      console.log(e.state)
      this.stateName = e.name
      this.postState = e.state
      this.search()
    },
    load () {
      var vm = this
      vm.$axios.post('/dictList').then(successData => {
        var data = successData.data
        vm.leibiehasId = data.data
        // vm.leibie = data.data
        var lx = vm._.filter(vm.leibiehasId, function (o) {
          return o.name === vm.$route.query.type
        })
        console.log(lx)
        var postData = {
          oppenId: vm.oppenId, // oppenId
          state: vm.postState, // 上方选的tab页面, 列表状态的:待处理=>dcl, 处理中=>clz, 待回访=>dhf, 已办结=>ybj
          currPage: vm.currPage, // 当前页码
          pageSize: 20, // 页面条数
          searchWord: vm.searchWord, // 搜索框中的关键字
          type: lx.length === 0 ? '' : lx[0].id // 类型
        }
        this.loading = true
        vm.currPage += 1
        vm.$axios.post('/getAppealList', postData).then(successData => {
        // vm.$axios.post('http://192.168.2.37:88/weChat/getAppealList', postData).then(successData => {
          console.log(successData.data)
          vm.returnData = successData.data.data[0]
          vm.totalPage = vm.returnData.totalPage
          vm.returnData.data.forEach(e => {
            vm.returnDataArr.push(e)
          })
          if (vm.currPage > vm.totalPage || vm.currPage === vm.totalPage) {
            vm.loadingAll = true
          } else {
            this.loading = false
          }
        })
      })
      // setTimeout(() => {
      //   this.loading = false
      //   this.num += 10
      //   this.currPage += 1
      //   this.currPage > this.totalPage && (this.loadingAll = true)
      // }, 800)
    },
    search () {
      this.currPage = 0
      this.returnDataArr = []
      this.load()
    },
    cardClick (id) {
      var vm = this
      setTimeout(() => {
        this.$parent.openFullscreenDialog('诉求详情', `./index.html#/sqxq?id=${id}&oppenId=${vm.oppenId}`)
        // this.$router.push({
        //   path: '/sqxq',
        //   query: {
        //     id: id
        //   }
        // })
      }, 400)
    }
  },
  mounted () {
    console.log(this.$route.query.type)
    this.load()
  }
}
</script>
<style lang="scss">
@import '../assets/css/wdsq.scss';
.mu-badge-circle {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  right: -19px;
}
</style>
