<!-- 最新资讯 -->
<template>
  <div class="con">
    <mu-container ref="container" class="loadmore-content">
      <mu-load-more :loading="loading" @load="load" :loaded-all="loadingAll">
        <!-- <el-card class="card animated fadeInRight" :style="`animation-delay:${Math.random() * 1}s`"  :body-style="{ padding: '0px' }" v-for="(e, i) in num" @click.native="cardClick(i)" :key="i">
          <div class="clearfix header">
            <span>公告:省外考察团本周五来我市考察，请各部门做好接待工作!</span>
          </div>
          <div class="body">
            <img src="../assets/img/001.png" class="image">
          </div>
          <div class="mx" style="padding: 14px;">
            <span>好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡好吃的汉堡</span>
            <div class="bottom clearfix">
              <div class="time">2020-01-01</div>
            </div>
          </div>
        </el-card> -->
        <el-card class="card animated fadeInRight" :style="`animation-delay:${Math.random() * 1}s`"  :body-style="{ padding: '0px' }" v-for="(e, i) in returnDataArr" @click.native="cardClick(e.id)" :key="i">
          <div class="clearfix header">
            <span>{{e.bt}}</span>
          </div>
          <div class="body">
            <img :src="e.img" class="image" v-if="e.img">
          </div>
          <div class="mx" style="padding: 14px;">
            <span v-html="e.mx"></span>
            <div class="bottom clearfix">
              <div class="time">{{e.time}}</div>
            </div>
          </div>
        </el-card>
      </mu-load-more>
      <div class="loadingWord" v-if="loadingAll">
        <mu-icon value="done" color="#A9DD88"></mu-icon>
        加载已完成
      </div>
    </mu-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      num: 0,
      // refreshing: false,
      loading: false,
      text: 'List',
      returnData: '',
      returnDataArr: [],
      currPage: 0, // 当前页
      totalPage: 2, // 总页数
      loadingAll: false // 是否已经完成加载
    }
  },
  components: {},
  computed: {},
  methods: {
    load () {
      var vm = this
      this.loading = true
      var postData = {
        currPage: vm.currPage, // 当前页码 // 0为第一页
        pageSize: 20 // 一页多少条
      }
      vm.$axios.post('/information', postData).then(successData => {
        vm.returnData = successData.data.data[0]
        vm.totalPage = vm.returnData.totalPage
        vm.returnData.data.forEach(e => {
          // e.mx = decodeURI(window.atob(e.mx)) // mx 后台传过来是base64
          vm.returnDataArr.push(e)
        })
        vm.currPage += 1
        if (vm.currPage > vm.totalPage || vm.currPage === vm.totalPage) {
          vm.loadingAll = true
        } else {
          this.loading = false
        }
      })
      // this.loading = true
      // setTimeout(() => {
      //   this.loading = false
      //   this.num += 10
      //   this.currPage += 1
      //   this.currPage > this.totalPage && (this.loadingAll = true)
      // }, 800)
    },
    cardClick (id) {
      console.log(id)
      setTimeout(() => {
        this.$parent.openFullscreenDialog('咨询详情', `index.html#/zxzxDetails?id=${id}`)
        // this.$router.push({
        //   path: '/zxzxDetails',
        //   query: {
        //     id: id
        //   }
        // })
      }, 400)
    }
  },
  mounted () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/zxzx.scss';
</style>
