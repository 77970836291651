<!-- 个人中心 -->
<template>
  <div class="grzxcon">
    <div class="headDiv">
      <div class="txImg"></div>
      <div class="mz">{{postName}}</div>
      <!-- <div class="tx animated fadeInLeft" style="animation-delay: 0.1s">
        <mu-avatar :size="80" style="display: none;">
          <img :src="imgUrl">
        </mu-avatar>
      </div>
      <div class="name animated fadeInLeft" style="animation-delay: 0.3s">
        <div class="nameItem">
          <mu-text-field class="nameItemIput" @keyup.enter="confimName" :readonly="readonly" v-model="postName" :max-length="maxLength" :change="postName = postName.replace(/\s+/g,'')"></mu-text-field>
          <div class="bj" @click="renameClick" style="visibility: hidden;">
            <img src="../assets/img/007.png" alt="">
          </div>
        </div>
        <div class="smItem" style="visibility: hidden;">
          <div>
            已经实名认证
          </div>
        </div>
      </div>
      <div class="jbgzh animated fadeInRight" style="animation-delay: 0.5s;visibility: hidden;">
        <mu-button class="jbgzhBtn" color="primary">解绑公众号</mu-button>
      </div> -->
    </div>
    <div class="grzxbodyDiv">
      <mu-list>
        <mu-list-item class="animated fadeInLeft" style="animation-delay: 0.7s" button @click="menuClick('咨询')">
          <mu-list-item-action>
            <img src="~@/assets/img/010.png" alt="">
          </mu-list-item-action>
          <mu-list-item-title>我的咨询</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon class="toggle-icon" size="32" value="keyboard_arrow_right"></mu-icon>
          </mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>

        <mu-list-item class="animated fadeInLeft" style="animation-delay: 0.9s" button @click="menuClick('建议')">
          <mu-list-item-action>
            <img src="~@/assets/img/012.png" alt="">
          </mu-list-item-action>
          <mu-list-item-title>我的建议</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon class="toggle-icon" size="32" value="keyboard_arrow_right"></mu-icon>
          </mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>

        <mu-list-item class="animated fadeInLeft" style="animation-delay: 1.1s" button @click="menuClick('求助')">
          <mu-list-item-action>
            <img src="~@/assets/img/013.png" alt="">
          </mu-list-item-action>
          <mu-list-item-title>我的求助</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon class="toggle-icon" size="32" value="keyboard_arrow_right"></mu-icon>
          </mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>

        <mu-list-item class="animated fadeInLeft" style="animation-delay: 1.3s" button @click="menuClick('表扬')">
          <mu-list-item-action>
            <img src="~@/assets/img/031.png" alt="">
          </mu-list-item-action>
          <mu-list-item-title>我的表扬</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon class="toggle-icon" size="32" value="keyboard_arrow_right"></mu-icon>
          </mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>

        <mu-list-item class="animated fadeInLeft" style="animation-delay: 1.5s" button @click="menuClick('举报')">
          <mu-list-item-action>
            <img src="~@/assets/img/032.png" alt="">
          </mu-list-item-action>
          <mu-list-item-title>我的举报</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon class="toggle-icon" size="32" value="keyboard_arrow_right"></mu-icon>
          </mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>

        <mu-list-item class="animated fadeInLeft" style="animation-delay: 1.9s" button @click="menuClick('投诉')">
          <mu-list-item-action>
            <img src="~@/assets/img/011.png" alt="">
          </mu-list-item-action>
          <mu-list-item-title>我的投诉</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon class="toggle-icon" size="32" value="keyboard_arrow_right"></mu-icon>
          </mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>

        <mu-list-item class="animated fadeInLeft" style="animation-delay: 1.7s" button @click="menuClick('其他')">
          <mu-list-item-action>
            <img src="~@/assets/img/030.png" alt="">
          </mu-list-item-action>
          <mu-list-item-title>其他</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon class="toggle-icon" size="32" value="keyboard_arrow_right"></mu-icon>
          </mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>

      </mu-list>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      maxLength: 0,
      readonly: true,
      postName: '',
      userInfo: {},
      imgUrl: ''
    }
  },
  components: {},
  computed: {},
  created () {
    // this.initUserOpenId()
    this.userInfo = this.$store.getters.getInitData.userInfo
    console.log(this.userInfo)
  },
  methods: {
    initUserOpenId () { // 得到用户的openId
      var userConfig = this.$ownerUtil.getAppUserCodeConfig()
      this.postName = userConfig.likename
    },
    initData () {
      var vm = this
      // var jsonData = JSON.stringify({ sfz: vm.userInfo.sfz })
      this.$axios.post('/personalCenterFind', { sfz: vm.userInfo.sfz, oppenId: vm.userInfo.oppenId }).then(successData => {
        console.log(successData.data)
      })
    },
    renameClick () {
      this.readonly = false
      this.maxLength = 6
    },
    confimName () { // 提交修改名称
      this.readonly = true
      this.maxLength = 0
      alert(this.postName)
    },
    menuClick (name) {
      this.$router.push({
        path: '/sqcx',
        query: {
          type: name
        }
      })
    }
  },
  mounted () {
    this.initData()
  }
}
</script>
<style lang="scss">
@import '../assets/css/grzx.scss';
</style>
