<!-- 最新资讯详情 -->
<template>
  <div class="detailscon">
    <div class="zxzxDetitle">
      {{returnData.bt}}
    </div>
    <div class="zxzxDetime">
      发布时间: {{returnData.time}}
    </div>
    <div class="contentbody" v-html="returnData.mx"></div>
    <div>
      <a :href="returnData.fileUrl" :style="{display: returnData.fileUrl.length > 0 ? 'block' : 'none'}" >{{returnData.fileName}} 下载</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      postUrl: {
        tzgg: '/announcementsDetails',
        rdjj: '/focusHotDetails',
        bmzs: '/peopleKnowledgeDetails'
      },
      returnData: {
        fileUrl: 'http://222.189.15.72:1688/weChat/downLoad?courseUrl=knim6w2yo30ayqgr1c',
        title: '',
        time: '',
        content: ''
      }
    }
  },
  components: {},
  computed: {},
  methods: {
    initFuntion () {
      console.log(this.$route.query.id)
      console.log(this.$route.query.type)
      var postUrl = this.postUrl[this.$route.query.type]
      var id = this.$route.query.id
      var vm = this
      this.$axios.post(postUrl, { id }).then((result) => {
        vm.returnData = result.data.data[0]
        // vm.returnData.content = decodeURI(window.atob(vm.returnData.content))
      })
    }
  },
  mounted () {
    this.initFuntion()
  }
}
</script>
<style lang="scss">
.detailscon {
  width: 100%;
  // height: 100%;
  padding: 3%;
  text-align: left;
}

.zxzxDetitle {
  font-size: 2rem;
  text-align: left;
  font-weight: 600;
  width: 100%;
}

.zxzxDetime {
  text-align: left;
  font-size: 1.4rem;
  color: #B6B6B6;
  // margin-top: 2%;
}

.contentbody {
  margin: 0;
  img {
    width: 100%;
  }
  p {
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: 2em;
  }
}
</style>
