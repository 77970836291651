<!-- 一键拨打电话 -->
<template>
  <div class="con">
    <div class="logo"></div>
    <div class="textDiv">灌南12345在线</div>
    <div class="telDiv">
      <a href="tel:0518-12345">立即拨号</a>
    </div>
    <!-- <a href="tel:0518-12345">0518-12345</a> -->
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  components: {},
  computed: {},
  methods: {},
  mounted () {}
}
</script>
<style lang="scss" scoped>
.con {
  width: 100%;
  height: 100%;
  background: url(~@/assets/img/038.png) no-repeat center;
  background-size: 100% 100%;

  .logo {
    width: 50%;
    height: 20%;
    margin: auto;
    position: relative;
    top: 15%;
    background: url(~@/assets/img/041.png) no-repeat center;
    background-size: 100%;
  }

  .textDiv {
    width: 100%;
    color: white;
    font-size: 2.2em;
    text-align: center;
    position: relative;
    top: 15%;
    font-weight: bolder;
    text-shadow: 3px 0px 0px #5B94D0;
  }

  .telDiv {
    width: 80%;
    height: 60px;
    margin: auto;
    position: relative;
    top: 50%;
    background: url(~@/assets/img/040.png) no-repeat center;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    a {
      width: 50%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &::before {
        background: black;
        display: block;
        width: 40px;
        height: 40px;
        content: '\A0';
        background: url(~@/assets/img/039.png) no-repeat center;
      }
    }
  }
}
</style>
