<!-- 在线客服 -->
<template>
  <div class="con">
    <div class="dialog" id="dialog">
      <div class="item" v-for="(e, i) in aiArr" :class="e.type" :key="i">
         <mu-paper class="itemMx" round :z-depth="5">
           {{e.mx}}
          <div class="itemTip" v-for="(f, c) in e.tipWord" :key="c" @click="tipQues(f)">
            {{f.bt}}
          </div>
          <div class="refreshBtn" v-if="false" @click="refreshClick(i)">
            <mu-icon value="sync" color="blue" size="18"></mu-icon>
            换一组
          </div>
         </mu-paper>
      </div>
    </div>
    <div class="personInput">
      <div class="inputDiv">
        <mu-text-field class="inputItem"  :max-length="100" label-float placeholder="请简短的描述你的问题" v-model="personWord" suffix="" @keyup.enter="personWordSendClick"></mu-text-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      aiArr: [
        // {
        //   id: 1,
        //   type: 'AiItem animated fadeInLeft',
        //   mx: '您好，很高兴为您服务，请问有什么可以帮您的呢',
        //   tipWord: ['1孩子上学需要哪些材料'],
        //   refreshBtn: false
        // }, {
        //   id: 2,
        //   mx: '12345猜您想问',
        //   type: 'AiItem animated fadeInLeft',
        //   tipWord: [
        //     '1孩子上学需要哪些材料',
        //     '2孩子上学需要哪些材料',
        //     '3孩子上学需要哪些材料',
        //     '4孩子上学需要哪些材料',
        //     '5孩子上学需要哪些材料',
        //     '6孩子上学需要哪些材料',
        //     '7孩子上学需要哪些材料'
        //   ],
        //   refreshBtn: true
        // }
      ],
      personWord: ''
    }
  },
  watch: {
    aiArr () {
      this.$nextTick(() => {
        var container = this.$el.querySelector('#dialog')
        container.scrollTop = container.scrollHeight
      })
    }
  },
  components: {},
  computed: {},
  methods: {
    initDataFun () {
      var vm = this
      vm.$axios.post('/customerServiceInit').then((result) => {
        console.log(result)
        result.data.data.forEach(e => {
          vm.aiArr.push(e)
        })
      })
    },
    tipQues (ques) {
      console.log(ques)
      var vm = this
      var postData = {
        mx: '', // 用户提交的关键字
        id: ques.id,
        type: 'personItem animated fadeInRight', // 对话框类型
        tipWord: [], // 均为写死数据不许添加任何值, 但是必须要有
        refreshBtn: false// 均为写死数据不许添加任何值, 但是必须要有
      }
      console.log(postData)

      vm.$axios.post('/customerServiceMx', postData).then(result => {
        console.log(result)
        result.data.data.forEach(e => {
          vm.aiArr.push(e)
        })
      })
    },
    refreshClick (i) {
      this.aiArr[i].tipWord = [`${Math.random() * 10}换了一组`]
    },
    personWordSendClick () {
      if (this.personWord !== '') {
        var words = {
          id: Math.random() * 10,
          mx: this.personWord,
          type: 'personItem animated fadeInRight',
          tipWord: [],
          refreshBtn: false
        }
        this.personWord = ''
        this.aiArr.push(words)
        var vm = this
        vm.$axios.post('/customerServiceKeywords', words).then((result) => {
          console.log(result)
          result.data.data.forEach(e => {
            vm.aiArr.push(e)
          })
          console.log(vm.aiArr)
          // this.aiArr.push(aiwords)
        })
      }
    }
  },
  mounted () {
    this.initDataFun()
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/zxkf.scss';
</style>
