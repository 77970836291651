<!-- 诉求详情 -->
<template>
  <div class="sqxqcon">
    <div class="stepDiv animated fadeIn">
      <el-steps :active="stepJson.activeStep" align-center>
        <el-step class="animated fadeInRight" :style="{'animation-delay': `${0.2 + i / 10}s`}" v-for="(e, i) in stepJson.stepArr" :key="i" :title="e"></el-step>
      </el-steps>
    </div>
    <div class="searchDiv animated fadeInRight" style="animation-delay: 0.8s" @click="showConsole">
      <mu-text-field class="inputItem" v-model="returnData.bt" label="标题" readonly suffix="" label-float ></mu-text-field>
    </div>
    <div class="searchDiv animated fadeInRight" style="animation-delay: 1s">
       <mu-text-field class="inputItem" v-model="returnData.zxr" label="咨询人" readonly  suffix="" label-float ></mu-text-field>
    </div>
    <div class="searchDiv animated fadeInRight" style="animation-delay: 1.2s">
      <mu-text-field class="inputItem" v-model="returnData.zxsj" label="咨询时间" readonly suffix="" label-float ></mu-text-field>
    </div>
    <div class="fieldDiv animated fadeInRight" style="animation-delay: 1.4s">
      <mu-text-field class="inputItem"  multi-line :rows="8" v-model="returnData.nr" readonly label="内容" suffix="" label-float ></mu-text-field>
    </div>
    <div class="imgDiv" v-if="returnData.imgUrl.length > 0">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(e, i) in returnData.imgUrl" :key="i">
          <!-- <img :src="`http://222.189.15.72:1888${e}`" alt="" srcset=""> -->
          <el-image
            style="width: 100%; height: 100%"
            :src="e"
          >
          </el-image>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="fieldDiv animated fadeInRight" style="animation-delay: 1.6s">
      <mu-text-field class="inputItem"  multi-line :rows="8" v-model="returnData.dfyj" readonly label="答复意见" suffix="" label-float ></mu-text-field>
    </div>
    <div class="searchDiv animated fadeInRight" style="animation-delay: 1.7s">
      <mu-text-field class="inputItem" v-model="returnData.dfsj" label="答复时间" readonly suffix="" label-float ></mu-text-field>
    </div>
    <div class="searchDiv animated fadeInRight" style="animation-delay: 1.9s">
      <mu-text-field class="inputItem" v-model="returnData.dfdw" label="答复单位" readonly suffix="" label-float ></mu-text-field>
    </div>
    <div class="btnZu animated zoomIn" style="animation-delay: 1.1s">
      <mu-button color="primary" class="btn" :disabled="!(stepJson.activeStep === 3 && returnData.dfyj !== '未回复')" full-width @click="btmOpen = !btmOpen">我要评价</mu-button>
    </div>

    <mu-bottom-sheet class="btmDiv" :open.sync="btmOpen" data-mu-loading-overlay-color="rgba(0, 0, 0, .7)" v-loading="btmloading">
      <div class="avatarDiv animated zoomIn" style="animation-delay: 0s;display: none;">
        <mu-avatar :size="80">
          <img src="~@/assets/img/001.png">
        </mu-avatar>
      </div>
      <div class="nameDiv animated zoomIn" style="animation-delay: 0.1s;display: none;">
        <div>{{postName}}</div>
        <div>已实名认证</div>
      </div>
      <div class="textDiv animated zoomIn" style="animation-delay: 0.2s">
        <div class="title">您的评价让我们做的更好</div>
        <div>你的反馈将有助于提高我们的12345处理系统</div>
      </div>
      <div class="textDiv animated zoomIn" style="animation-delay: 0.3s">
        <div>结果满意度</div>
      </div>
      <div class="dfDiv animated zoomIn" style="animation-delay: 0.4s">
        <el-rate
          v-model="rateValue"
          :colors="rateColors"
          show-score
          score-template="{value}分"
        >
        </el-rate>
      </div>
      <div class="textDiv animated zoomIn" style="animation-delay: 0.3s">
        <div>作风满意度</div>
      </div>
      <div class="dfDiv animated zoomIn" style="animation-delay: 0.4s">
        <el-rate
          v-model="zfmydRateValue"
          :colors="rateColors"
          show-score
          score-template="{value}分"
        >
        </el-rate>
      </div>
      <div class="inputDiv animated zoomIn" style="animation-delay: 0.5s">
        <div class="item">
          <mu-text-field class="input" v-model="jy" placeholder="添加评论" multi-line :rows="3" :max-length="500"></mu-text-field><br/>
        </div>
      </div>
      <div class="pjbtnDiv animated zoomIn" style="animation-delay: 0.6s">
        <mu-button color="primary" class="btn" full-width @click="postPjFun">提交评价</mu-button>
      </div>
    </mu-bottom-sheet>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  data () {
    return {
      clickCount: 0,
      swiperOptions: {
        loop: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination'
        }
      },
      oppenId: '',
      returnData: {
        bt: '',
        zxr: '',
        zxsj: '',
        nr: '',
        dfyj: '',
        dfsj: '',
        dfdw: '',
        imgUrl: []
      },
      btmOpen: false, // 是否显示评价框
      btmloading: false, // 是否显示loading区域
      rateColors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      stepJson: {
        activeStep: 4,
        stepArr: ['提交', '审核', '处理中', '评价', '已办结']
      },
      postName: '',
      rateValue: 5, // 分值
      zfmydRateValue: 5, // 作风满意度评分
      jy: '' // 建议
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  created () {
    // this.initUserOpenId()
  },
  methods: {
    showConsole () {
      var vm = this
      vm.clickCount++
      if (vm.clickCount === 10) {
        var Vconsole = this.$Vconsole
        Vconsole = new Vconsole()
        console.log(Vconsole)
      }
    },
    initUserOpenId () { // 得到用户的openId
      // var userConfig = this.$ownerUtil.getAppUserCodeConfig()
      // this.oppenId = userConfig.openid
      // this.postName = userConfig.likename
      // var objectData = {
      //   appId: this.$store.getters.getInitData.appId,
      //   appSecret: this.$store.getters.getInitData.appSecret
      // }
      // this.oppenId = this.$ownerUtil.getUserCodeConfig('xxgrxx', objectData)
      // this.oppenId = this.oppenId.result
    },
    initDataFun () {
      var vm = this
      var postData = {
        oppenId: vm.$route.query.oppenId,
        id: vm.$route.query.id
      }
      console.log(postData)
      vm.$axios.post('/appealDetails', postData).then((result) => {
        console.log(result)
        vm.returnData = result.data.data[0]
        vm.stepJson.activeStep = result.data.data[0].stepJson.activeStep
      })
    },
    postPjFun () {
      var vm = this
      var postData = {
        xm: vm.postName,
        id: vm.$route.query.id,
        rateValue: vm.rateValue,
        zfmydRateValue: vm.zfmydRateValue, // 作风满意度分数
        pl: vm.jy
      }
      console.log(postData)
      vm.btmloading = true
      vm.$axios.post('/evaluate', postData).then(successData => {
        console.log(successData.data)
        if (successData.data.data.isSuccess) {
          vm.$alert('提交成功', '', { okLabel: '确定' }).then(() => {
            vm.btmOpen = false
            vm.btmloading = false
            // vm.stepJson.activeStep = 3
          })
        } else {
          vm.$alert('提交失败', '', { okLabel: '确定' }).then(() => {
            // vm.btmOpen = false
            vm.btmloading = false
          })
        }
        // infoDataLoading.close()
      })
    }
  },
  mounted () {
    console.log(this.$route.query.id)
    this.initDataFun()
  }
}
</script>
<style lang="scss">
@import '../assets/css/sqxq.scss';
</style>
