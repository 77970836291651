<!-- 绑定个人详细信息 -->
<template>
  <div class="con">
    <mu-form ref="form" :model="postFormValue" class="mu-form">
      <mu-form-item class="searchDiv"  label="姓名" label-float prop="xm" :rules="xmRules">
        <mu-text-field v-model="postFormValue.xm" :change="postFormValue.xm = postFormValue.xm.replace(/\s+/g,'')" prop="xm"></mu-text-field>
      </mu-form-item>
      <!-- <mu-form-item class="searchDiv"  label="身份证号" label-float prop="sfz"  :rules="sfzRules">
        <mu-text-field v-model="postFormValue.sfz" prop="sfz" :max-length="18"></mu-text-field>
      </mu-form-item> -->
      <mu-form-item class="searchDiv"  label="手机号" label-float prop="phone" :rules="phoneRules">
        <mu-text-field v-model="postFormValue.phone" label-float></mu-text-field>
      </mu-form-item>
    </mu-form>
    <!-- <div class="cardSelect">
      <div class="uploadDiv">
        <el-upload action class="zhengmianCard" :on-change="overImg" ref="my-upload" list-type="picture-card" accept="image/jpeg,image/png,image/gif" :auto-upload="false" :limit="2">
          <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" >
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-delete" @click="handleRemove(file)" >
                  <i class="el-icon-delete"></i>
                </span>
                <span  class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)" >
                  <i class="el-icon-zoom-in"></i>
                </span>
              </span>
            </div>
        </el-upload>

        <div class="elInput">
          <mu-text-field class="input" disabled :placeholder="imgDoneWord">
            <mu-icon value="cloud_upload" v-if="!imgDone" right></mu-icon>
            <mu-icon value="cloud_done" v-if="imgDone" right color="blue"></mu-icon>
          </mu-text-field><br/>
        </div>
      </div>

      <div class="uploadDiv">
        <el-upload action class="fanmianCard" :on-change="overImg" ref="fanmianCard" list-type="picture-card" accept="image/jpeg,image/png,image/gif" :auto-upload="false" :limit="2">
          <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" >
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-delete" @click="handleRemove(file)" >
                  <i class="el-icon-delete"></i>
                </span>
                <span  class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)" >
                  <i class="el-icon-zoom-in"></i>
                </span>
              </span>
            </div>
        </el-upload>
        <div class="elInput">
          <mu-text-field class="input" disabled :placeholder="imgDoneWordFan">
            <mu-icon value="cloud_upload" v-if="!imgDoneFan" right></mu-icon>
            <mu-icon value="cloud_done" v-if="imgDoneFan" right color="blue"></mu-icon>
          </mu-text-field><br/>
        </div>
      </div>

    </div> -->
    <div class="btnZu">
      <mu-button color="primary" full-width @click="submitUpload">提交</mu-button>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="100%">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      disabled: false,
      commitBtn: false,
      xmRules: [
        { validate: (val) => !!val, message: '必须填写姓名' },
        { validate: (val) => val.length >= 2, message: '请填写正确的姓名' }
      ],
      // sfzRules: [
      //   { validate: (val) => !!val, message: '必须填写身份号' },
      //   { validate: (val) => val.length === 18, message: '请填写正确的身份号' }
      // ],
      phoneRules: [
        { validate: (val) => !!val, message: '必须填写手机号' },
        { validate: (val) => val.length === 11, message: '请填写正确的手机号' }
      ],
      imgDone: false,
      imgDoneWord: '身份证正面照片',
      imgDoneFan: false,
      imgDoneWordFan: '身份证反面照片',
      dialogImageUrl: '',
      dialogVisible: false,
      photoArr: ['', ''],
      postFormValue: {
        oppenId: '',
        xm: '',
        sfz: '',
        phone: '',
        zhengmianCard: '',
        fanmianCard: ''
      },
      postFormValue2: {
        oppenId: '',
        xm: '',
        sfz: '',
        phone: '',
        zhengmianCard: '',
        fanmianCard: ''
      }
    }
  },
  components: {},
  computed: {},
  created () {
    this.initUserOpenId()
  },
  methods: {
    handleRemove (file) {
      this.$refs['my-upload'].uploadFiles = this._.remove(this.$refs['my-upload'].uploadFiles, function (o) {
        return o.uid !== file.uid
      })
      if (this.$refs['my-upload'].uploadFiles.length < 1) {
        this.photoArr[0] = ''
        setTimeout(() => {
          this.$jq('.zhengmianCard .el-upload').css({ display: 'inline-block' })
        }, 1000)
      }
      // this.$refs['my-upload'].uploadFiles.length < 1 && this.$jq('.el-upload').css({ display: 'inline-block' })
      if (this.$refs['my-upload'].uploadFiles.length < 1) {
        this.imgDone = false
        this.imgDoneWord = '身份证正面面图片'
      }

      // 身份证反面
      this.$refs.fanmianCard.uploadFiles = this._.remove(this.$refs.fanmianCard.uploadFiles, function (o) {
        return o.uid !== file.uid
      })
      if (this.$refs.fanmianCard.uploadFiles.length < 1) {
        this.photoArr[1] = ''
        setTimeout(() => {
          this.$jq('.fanmianCard .el-upload').css({ display: 'inline-block' })
        }, 1000)
      }
      // this.$refs['my-upload'].uploadFiles.length < 1 && this.$jq('.el-upload').css({ display: 'inline-block' })
      if (this.$refs.fanmianCard.uploadFiles.length < 1) {
        this.imgDoneFan = false
        this.imgDoneWordFan = '身份证反面图片'
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    submitUpload () {
      var vm = this
      this.$refs.form.validate().then((result) => {
        if (result) {
          vm.$axios.post('/personalInformation', vm.postFormValue).then(successData => {
            console.log(successData)
            if (successData.data.data.isSuccess) {
              this.$alert('绑定成功', '', {
                okLabel: '确定'
              }).then(() => {
                vm.$store.dispatch('setUserInfo', vm.postFormValue)
                vm.$router.push('/wysq')
              })
            } else {
              // vm.$store.dispatch('setUserInfo', vm.postFormValue)
              this.$alert('绑定失败', '', { okLabel: '确定' }).then(() => {
                // vm.$router.push('/grzx')
              })
            }
          })
        }
      })
    },
    submitUpload2 () { // 用户信息提交
      var vm = this
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (vm.photoArr[0] !== '' && vm.photoArr[1] !== '') {
          // if (this.$refs['my-upload'].uploadFiles.length > 0 && this.$refs.fanmianCard.uploadFiles.length > 0) {
            // this.postFormValue.zhengmianCard = this.$refs['my-upload'].uploadFiles[0]
            // this.postFormValue.fanmianCard = this.$refs.fanmianCard.uploadFiles[0]
            // var zhengMianReader = new FileReader()
            // zhengMianReader.readAsDataURL(this.$refs['my-upload'].uploadFiles[0].raw)
            // zhengMianReader.onload = function () {
            //   vm.postFormValue.zhengmianCard = this.result
            // }
            // var fanMianReader = new FileReader()
            // fanMianReader.readAsDataURL(this.$refs.fanmianCard.uploadFiles[0].raw)
            // fanMianReader.onload = function () {
            //   vm.postFormValue.fanmianCard = this.result
            // }
            vm.postFormValue.zhengmianCard = vm.photoArr[0]
            vm.postFormValue.fanmianCard = vm.photoArr[1]
            vm.$axios.post('/personalInformation', vm.postFormValue).then(successData => {
              console.log(successData)
              if (successData.data.data.isSuccess) {
                this.$alert('绑定成功', '', {
                  okLabel: '确定'
                }).then(() => {
                  vm.$store.dispatch('setUserInfo', vm.postFormValue)
                  vm.$router.push('/wysq')
                })
              } else {
                // vm.$store.dispatch('setUserInfo', vm.postFormValue)
                this.$alert('绑定失败', '', { okLabel: '确定' }).then(() => {
                  // vm.$router.push('/grzx')
                })
              }
            })
          } else {
            this.$message.error('身份证图片未选择完整')
          }
        }
      })
    },
    overImg () { // 用于在选完一张图片后隐藏下一个图片选择框
      var vm = this
      vm.$refs['my-upload'].uploadFiles.length > 0 && this.$jq('.zhengmianCard .el-upload').css({ display: 'none' })
      vm.$refs['my-upload'].uploadFiles.length > 0 && (vm.imgDone = !true)
      if (vm.$refs['my-upload'].uploadFiles.length > 0) {
        vm.imgDone = true
        vm.imgDoneWord = '已选择'
      }

      vm.$refs.fanmianCard.uploadFiles.length > 0 && this.$jq('.fanmianCard .el-upload').css({ display: 'none' })
      vm.$refs.fanmianCard.uploadFiles.length > 0 && (vm.imgDoneFan = !true)
      if (vm.$refs.fanmianCard.uploadFiles.length > 0) {
        vm.imgDoneFan = true
        vm.imgDoneWordFan = '已选择'
      }

      if (this.$refs['my-upload'].uploadFiles.length > 0 && this.$refs.fanmianCard.uploadFiles.length > 0) {
        var zhengMianReader = new FileReader()
        zhengMianReader.readAsDataURL(this.$refs['my-upload'].uploadFiles[0].raw)
        zhengMianReader.onload = function () {
          vm.photoArr[0] = this.result
          // vm.postFormValue.zhengmianCard = this.result
        }
        var fanMianReader = new FileReader()
        fanMianReader.readAsDataURL(this.$refs.fanmianCard.uploadFiles[0].raw)
        fanMianReader.onload = function () {
          vm.photoArr[1] = this.result
          // vm.postFormValue.fanmianCard = this.result
        }
      }
    },
    initUserOpenId () { // 得到用户的openId
      var objectData = {
        appId: this.$store.getters.getInitData.appId,
        appSecret: this.$store.getters.getInitData.appSecret
      }
      this.postFormValue.oppenId = this.$ownerUtil.getUserCodeConfig('xxgrxx', objectData)
      this.postFormValue.oppenId = this.postFormValue.oppenId.data.openId
      // setTimeout(() => {
      //   this.$ownerUtil.getWxLocaltion()
      // }, 1000)
      console.log(this.postFormValue.oppenId)
    }
  },
  mounted () {
    // this.initUserOpenId()
  }
}
</script>
<style lang="scss">
@import '../assets/css/xxgrxx.scss';
</style>
