<!-- 微信随手拍 -->
<template>
  <div class="con">
    <iframe v-if="oppenId" :src="iframeUrl" frameborder="0"></iframe>
  </div>
</template>

<script>
const wx = window.wx
const BMapGL = window.BMapGL
export default {
  data () {
    return {
      iframeUrl: '',
      oppenId: ''
    }
  },
  components: {},
  computed: {},
  methods: {},
  mounted () {
    var vm = this
    // vm.oppenId = 'ovNPw5tfw6gHjZpOzVniT7KCx9lc'
    var objectData = {
      appId: this.$store.getters.getInitData.appId,
      appSecret: this.$store.getters.getInitData.appSecret
    }
    this.oppenId = this.$ownerUtil.getUserCodeConfig('ssp', objectData)
    this.oppenId = this.oppenId.data.openId

    // var Vconsole = this.$Vconsole
    // this.Vconsole = new Vconsole()
    // console.log(objectData)
    // console.log(this.oppenId)

    var postData = {
      url: location.href.split('#')[0],
      code: this.$ownerUtil.GetRequest().code
    }

    var postUrl = 'https://xcx.gnxzhzx.com/websites/wxConfig.jsp'
    vm.$jq.post(postUrl, postData, (res) => {
      var signParam = res.data
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: signParam.appId, // 必填，公众号的唯一标识
        timestamp: signParam.timestamp, // 必填，生成签名的时间戳
        nonceStr: signParam.nonceStr, // 必填，生成签名的随机串
        signature: signParam.signature, // 必填，签名，见官网附录1
        jsApiList: ['getLocation', 'scanQRCode']
      })

      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: [
            'getLocation'
          ],
          success: function (res) {
            if (!res.checkResult.getLocation) {
              alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！')
              return false
            } else {
              console.log(res)
            }
          }
        })

        wx.getLocation({
          success: function (res) {
            if (res.errMsg === 'getLocation:ok') {
              var convertor = new BMapGL.Convertor()
              var COORDINATES_WGS84, COORDINATES_BD09
              convertor.translate([new BMapGL.Point(res.longitude, res.latitude)], COORDINATES_WGS84, COORDINATES_BD09, function (translateData) {
                console.log(translateData.points)
                vm.iframeUrl = `https://lyggn.gnxzhzx.com/mobile/h5/guannan/ssp/index.html?area=guannan&longitude=${translateData.points[0].lng}&latitude=${translateData.points[0].lat}&openId=${vm.oppenId}`
              })
            } else {
              // vm.postFormValue.address = '获取位置失败, 请您手动填写'
              vm.iframeUrl = `https://lyggn.gnxzhzx.com/mobile/h5/guannan/ssp/index.html?area=guannan&openId=${vm.oppenId}&longitude=&latitude=`
            }
          },
          cancel: function (res) {
            // vm.postFormValue.address = '您拒绝获取位置, 请您手动填写'
            vm.iframeUrl = `https://lyggn.gnxzhzx.com/mobile/h5/guannan/ssp/index.html?area=guannan&openId=${vm.oppenId}&longitude=&latitude=`
          }
        })
      })
    }, 'json')
  }
}
</script>
<style lang="scss" scoped>
.con {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
