<!-- 通知公告 -->
<template>
  <div class="con">
    <!-- <mu-paper class="card" round :z-depth="4">
      <div class="title">
        第141、142期便民公告(3月24日)
      </div>
      <div class="body">
        周四(3月24日)根据《全国妇联关于表彰全国.......
      </div>
      <div class="time">
        2020-3-21 00:00:00
      </div>
    </mu-paper> -->
    <mu-container ref="container" class="loadmore-content">
      <mu-load-more :loading="loading" @load="load" :loaded-all="loadingAll">
        <mu-paper class="card animated fadeInRight" @click="cardClick(e.id)" :style="`animation-delay:${Math.random() * 1}s`" round :z-depth="4" v-for="(e, i) in returnDataArr" :key="i">
          <div class="title">
            {{ e.bt }}
          </div>
          <div class="body">
            <div class="nr">
              {{ e.mx }}
            </div>
            <div class="more">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="time">
            {{ e.time }}
          </div>
        </mu-paper>
      </mu-load-more>
      <div class="loadingWord" v-if="loadingAll">
        <mu-icon value="done" color="#A9DD88"></mu-icon>
        加载已完成
      </div>
    </mu-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      num: 0,
      // refreshing: false,
      returnData: '',
      returnDataArr: [],
      loading: false,
      text: 'List',
      currPage: 0, // 当前页
      totalPage: 1, // 总页数
      loadingAll: false // 是否已经完成加载
    }
  },
  components: {},
  computed: {},
  methods: {
    load () {
      this.loading = true
      var vm = this
      var postData = {
        currPage: vm.currPage, // 当前页码 // 0为第一页
        pageSize: 20 // 一页多少条
      }
      vm.currPage += 1
      vm.$axios.post('/announcements', postData).then(successData => {
        console.log(successData)
        vm.returnData = successData.data.data[0]
        vm.totalPage = vm.returnData.totalPage
        vm.returnData.data.forEach(e => {
          // e.mx = decodeURI(window.atob(e.mx)) // mx 后台传过来是base64
          vm.returnDataArr.push(e)
        })
        if (vm.currPage > vm.totalPage || vm.currPage === vm.totalPage) {
          vm.loadingAll = true
        } else {
          this.loading = false
        }
      })
      // setTimeout(() => {
      //   this.loading = false
      //   this.num += 10
      //   this.currPage += 1
      //   this.currPage > this.totalPage && (this.loadingAll = true)
      // }, 800)
    },
    cardClick (id) {
      setTimeout(() => {
        this.$parent.openFullscreenDialog('公告详情', `index.html#/infoDetails?id=${id}&type=tzgg`)
        // this.$router.push({
        //   path: '/zxzxDetails',
        //   query: {
        //     id: id
        //   }
        // })
      }, 400)
    }
  },
  mounted () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/tzgg.scss';
</style>
