<!-- 绑定个人信息 -->
<template>
  <div class="con">
    <mu-form ref="form" :model="postData" class="mu-form">
      <mu-form-item class="searchDiv"  label="请输入11位手机号码" label-float prop="phone" :rules="phRules">
        <!-- <mu-text-field v-model="postData.phone" :max-length="2"></mu-text-field><br/> -->
        <mu-text-field v-model="postData.phone" :max-length="11" :change="postData.phone = postData.phone.replace(/[^\d]/g,'')" prop="phone"></mu-text-field>
      </mu-form-item>
      <mu-form-item class="codeDiv"  label="请输入验证码" label-float prop="code" :rules="codeRules">
        <div class="itemInput">
          <mu-text-field v-model="postData.code" :max-length="4" :change="postData.code = postData.code.replace(/[^\d]/g,'')" prop="code"></mu-text-field>
        </div>
        <div class="itemBtn">
          <mu-button color="info" :disabled="disabledBtn" @click="sendCode" small>
            {{codeBtnWord}}
          </mu-button>
        </div>
      </mu-form-item>
      <mu-form-item class="searchDiv" label-float prop="phone">
        <div class="nextBtn">
          <mu-button @click="postUserInfo" color="info" :disabled="nextBtn" full-width>
            下一步
          </mu-button>
        </div>
      </mu-form-item>
    </mu-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      number: 60, // 短信发送间隔
      disabledBtn: true,
      nextBtn: true,
      codeBtnWord: '发送验证码',
      noSecondSend: true, // 防止修改手机号后再次发送按钮可以再次点击
      phRules: [
        { validate: (val) => !!val, message: '必须填写手机号码' },
        { validate: (val) => val.length === 11, message: '请填写正确的手机号码' }
      ],
      codeRules: [
        { validate: (val) => !!val, message: '必须填写验证码' },
        { validate: (val) => val.length >= 4, message: '请填写正确的验证码' }
      ],
      postData: {
        phone: '',
        code: ''
      }
    }
  },
  watch: {
    postData: {
      handler: function (val, oldVal) {
        if (val.phone.length === 11) {
          if (this.noSecondSend) {
            this.disabledBtn = false
          } else {
            this.disabledBtn = true
          }
        } else {
          this.disabledBtn = true
        }
        if (val.phone.length === 11 && val.code.length === 4) {
          this.nextBtn = false
        } else {
          this.nextBtn = true
        }
      },
      deep: true // 对象内部的属性监听，也叫深度监听
    }
  },
  components: {},
  computed: {
    // phIsNull () {
    //   return this.postData.phone
    // },
    // codeIsNull () {
    //   return this.postData.code
    // }
  },
  methods: {
    initFun () {
      // this.initUserOpenId()
    },
    initUserOpenId () {
      var objectData = {
        appId: this.$store.getters.getInitData.appId,
        appSecret: this.$store.getters.getInitData.appSecret
      }
      var openId = this.$ownerUtil.getUserCodeConfig('bdgrxx', objectData)
      this.postData.openId = openId
    },
    sendCode () {
      if (this.noSecondSend) {
        this.disabledBtn = true // 禁用发送验证码按钮
        this.number = 60
        var codeTime = setInterval(() => {
          if (this.number === 0) {
            clearInterval(codeTime)
            this.disabledBtn = false
            this.codeBtnWord = '发送验证码'
            this.noSecondSend = true
          } else {
            this.noSecondSend = false
            this.number -= 1
            this.codeBtnWord = `${this.number}秒`
          }
        }, 1000)

        console.log('发送了验证码')
        // if 里面写发送验证码的代码
      } else {
        this.disabledBtn = false
      }
    },
    postUserInfo () {
      console.log(this.postData)
      // alert(JSON.stringify(this.postData))
      this.$store.dispatch('setUserPhone', this.postData.phone)
      this.$router.push({
        path: '/xxgrxx'
      })
    }
  },
  mounted () {
    this.initFun()
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/bdgrxx.scss';
</style>
