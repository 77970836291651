<!-- 中心简介 -->
<template>
  <div class="con">
    <mu-paper class="card animated zoomIn" round :z-depth="4">
      <div class="title">
        12345微信服务系统
      </div>
      <div class="body">
        12345服务热线各地情况都不同，一般由各地市人民政府设立。
      </div>
    </mu-paper>
    <div class="cardBody">
      <div class="cardItem animated fadeInRight" :style="`animation-delay:${Math.random() * 1}s`">
        <div class="title">中心职责</div>
        <div class="body" v-html="initData.zxjj"></div>
      </div>
      <div class="cardItem animated fadeInRight" :style="`animation-delay:${Math.random() * 1}s`">
        <div class="title">联系我们</div>
        <div class="body">
          中心地址：{{initData.lxwm.zxdz}} <br />
          咨询电话：{{initData.lxwm.zxdh}} <br />
          联系邮箱：{{initData.lxwm.lxyx}} <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      initData: {
        lxwm: {
          zxdz: ''
        }
      }
    }
  },
  components: {},
  computed: {},
  methods: {
    initDataFun: function () {
      this.$axios.post('/centerIntroduction').then(successData => {
        this.initData = successData.data.data[0]
      })
    }
  },
  mounted () {
    this.initDataFun()
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/zxjj.scss';
</style>
